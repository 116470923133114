import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

export default () => (
  <Layout>
    <SEO title="Expanded Metal" />
    <Container className="singleCol">
      <h1>Expanded Metal</h1>
      <p>
        We offer a full line of expanded metal products made from a wide range of materials, including stainless steel, carbon steel, and aluminum. Expanded metal is a very versatile material, made from sheets of solid metal that are uniformly slit and stretched, forming a diamond pattern of openings in the sheet.
      </p>
      <p>
        The pattern varies by the gauge and the type of material and the size of the diamond. It’s offered in raised or flattened sheets. For full details and specifications: Please call us at (510) 887-8787.
      </p>
      <p>
        For more information, visit: <a href="http://howardwire.com/expanded_metals.html">http://howardwire.com/expanded_metals.html</a>.
      </p>
    </Container>
  </Layout>
)
